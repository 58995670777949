/* eslint-disable no-undef */
document.addEventListener("DOMContentLoaded", () => {
  const btnActiveFullScreen = document.querySelectorAll('[data-open-fullscreen]')
  
  btnActiveFullScreen.forEach(btn => {
    const element_id = btn.dataset.openFullscreen
    const element = document.getElementById(element_id)

    btn.addEventListener('click', e => {
      e.preventDefault()
      openFullscreen(element)
    })
  })
})

function openFullscreen(elem = null) {
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) { /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) { /* IE11 */
    elem.msRequestFullscreen();
  }
}

function closeFullscreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen();
  } else if (document.webkitExitFullscreen) { /* Safari */
    document.webkitExitFullscreen();
  } else if (document.msExitFullscreen) { /* IE11 */
    document.msExitFullscreen();
  }
}
